/* eslint-disable no-console */
import { closeDialog } from '../../features/state/slices/dialogsSlice';

import { NavigationBar } from './NavigationBar';
import Error from '../common/Error';
import { useEffect } from 'react';
import { getNotifications } from '../../api/endpoints/notifications';
import { setNotifications } from '../../features/state/slices/notificationsSlice';
import { getUsers, getCurrentUser } from '../../api/endpoints/users';
import { setCurrentUser } from '../../features/state/slices/currentUserSlice';

import Notification from '../../models/notification';
import User from '../../models/user';
import { useApi } from '../../api/ApiContext';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import { Json } from '../../models/base';
import { useOktaAuth } from '@okta/okta-react';
import OktaAuthModal from '../auth/OktaAuthModal';
import Footer from './Footer';

type PageLayoutProps = {
  children: React.ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  const { apiInstance, setOktaInitialized } = useApi();
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const isAuthenticated = authState && authState.isAuthenticated;
  const dispatch = useAppDispatch();
  const dialog = useAppSelector((state) => state.dialog.value);

  useEffect(() => {
    if (!apiInstance.oktaInitialized) return;
    const _getCurrentUser = () => {
      getCurrentUser(apiInstance)
        .then((response) => {
          const parsedUser = User.fromJsonToReduxJson(response.responseAsJson);
          dispatch(setCurrentUser(parsedUser));
        })
        .catch((error) => {
          console.log('Error getting current user', error);
        });
    };

    const _getNotificationsWithUsers = async () => {
      const parsedNotifications = await getNotifications(apiInstance, null)
        .then((response) =>
          Notification.fromJsonArrayToObjectReduxJsonArray(response.responseAsJson),
        )
        .catch((error) => {
          console.log('Error getting notifications', error);
        });

      if (parsedNotifications === undefined) return;

      const userIds: string[] = [];
      parsedNotifications.forEach((notificationJson: Json) => {
        const notification = notificationJson as Notification;
        if (notification.isUserMessage && !userIds.includes(notification.sourceId))
          userIds.push(notification.sourceId);
      });

      let notificationUsers = await getUsers(apiInstance, userIds)
        .then((response) => response.responseAsJson)
        .catch((error) => {
          console.log('Error getting notification users', error);
        });

      if (notificationUsers) {
        parsedNotifications.forEach((notification) => {
          const user = notificationUsers.find((user: User) => user.id === notification.sourceId);
          if (user) notification.userName = user.name;
        });
      }
      dispatch(setNotifications(parsedNotifications));
    };
    _getCurrentUser();
    _getNotificationsWithUsers();
  }, [dispatch, apiInstance]);

  useEffect(() => {
    if (!authState?.isAuthenticated) return;
    if (authState?.accessToken) {
      setOktaInitialized(true); // Enable API calls
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, authState, navigate]);

  return (
    <>
      {isAuthenticated && <NavigationBar />}
      {children}
      <OktaAuthModal />
      <Error
        open={dialog.open}
        errorTitle={dialog.title}
        errorMessage={dialog.message}
        onClickOk={() => dispatch(closeDialog())}
      />
      <Footer />
    </>
  );
};
