import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer-container">
      <div className="footer-link-container">
        <a
          className="footer-link"
          href="https://www.halliburton.com/en/about-us/corporate-governance/terms-and-conditions"
          target="_blank">
          Terms and Conditions |
        </a>
        <a
          className="footer-link"
          href="https://www.halliburton.com/en/about-us/corporate-governance/privacy-policy"
          target="_blank">
          Privacy Policy
        </a>
      </div>
      <p className="hal-info">
        This website is designed to assist users in searching for products and providing general
        information. While we make every effort to ensure the accuracy of the information presented,
        We recommend confirming details directly with us by contacting Completions@Halliburton.com.
        By using this website, you acknowledge your understanding of these limitations.
      </p>
      <p className="hal-info">2000-{currentYear} Halliburton All Rights Reserved</p>
    </footer>
  );
};

export default Footer;
