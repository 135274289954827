import React from 'react';
import Milestone from '../../assets/icons/milestone.svg';
import StepCheckMark from '../../assets/icons/step_check_mark.svg';
import { MILESTONE_STATUS } from '../../constants/salesOrder';
import { MilestoneStatus, ProjectStage } from '../../types/ProjectTypes';

interface MilestoneItemProps {
  item: ProjectStage;
}

const MilestoneItem = ({ item }: MilestoneItemProps) => {
  const renderStatusIcon = (status: MilestoneStatus) => {
    switch (status) {
      case MILESTONE_STATUS.Complete:
        return (
          <span className={'status-icon'}>
            <img className={'status-icon'} src={StepCheckMark} alt="Status icon" />
          </span>
        );
      case MILESTONE_STATUS.InProgress:
        return <span className={'status-icon circle in-progress'} />;
      case MILESTONE_STATUS.NotStarted:
      default:
        return <span className={'status-icon circle not-started'} />;
    }
  };

  let title = item.milestones![0].title;
  item.milestones!.forEach((item) => {
    if (item.completionDate === MILESTONE_STATUS.Complete) {
      title = item.title;
    }
  });

  return (
    <div className="dispatch-item-container">
      <div className="title-container">
        <img src={Milestone} alt="Milestone icon" />
        <div className="title">{title}</div>
      </div>
      <div className="status-icon-container">
        {item.milestones!.map((item, idx) => (
          <React.Fragment key={idx}>{renderStatusIcon(item.completionDate)}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MilestoneItem;
